import React, { useContext, useEffect } from "react"
import AuthContext from '../../../context/AuthProvider';
// import { Link } from "gatsby";
import Layout from "../../organisms/Layout/Layout"
import Seo from "../../organisms/Seo/Seo"
import Container from "../../atoms/Container/Container"
// import PageTitle from "../../atoms/PageTitle/PageTitle"
import Breadcrumbs from "../../atoms/Breadcrumbs/Breadcrumbs";
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'
import * as styles from './AccountPageWrapper.module.css'
import ContentfulContext from "../../../context/ContentfulProvider";
import { get } from "lodash";

const AccountPageWrapper = ({ children, metaTitle, title }) => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const auth = useContext(AuthContext);
    const userChecked = auth && auth.state.userChecked;
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const customerId = auth && auth.state.customerId;
    const firstName = auth && 'object' in auth.state && auth.state.object.first_name;
    const hide = true;

    useEffect(() => {
        // console.log(fieldErrors);
        if (userChecked && !isLoggedIn && typeof window !== 'undefined') {
            window.location = '/login';
        }
    }, [isLoggedIn, userChecked])

    if (isLoggedIn) {
        const childPage = React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { customerId })
            }

            return child
        })

        return (
            <Layout>
                <Seo title={`Account - ${metaTitle}`} />
                <Container size="large">
                    <div className={`grid ${styles.AccountWrapper}`}>
                        <div className={styles.sideMenu}>
                            <AccountPageMenu isLoggedIn={isLoggedIn} firstName={firstName} isHidden={hide}/>
                        </div>
                        <div>
                            <Breadcrumbs crumbs={[{link: '/', label: get(translations, 'home.title', 'Home')}, {link: '/account/', label: get(translations, 'account.title', 'Account')}, {label:`${title}`}]} />
                            {/* <div className={styles.breadCrumb}><Link to="/">Home</Link> &gt; <Link to="/account">Account</Link> &gt; {title}</div> */}
                            <h1 className={`h6 ${styles.accountTitle}`}>{title}</h1>
                            {/* <PageTitle title={title} /> */}
                            { childPage }
                        </div>
                    </div>
                </Container>
            </Layout>
        )
    } else {
        return (
            <Layout>
                <Seo title="Login" />
                <Container>
                    
                </Container>
            </Layout>
        )
    }
}

export default AccountPageWrapper